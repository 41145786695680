<template>
    <div>
        <styled-card
            class="mt-5 mb-5"
            :width="1000"
            hide-toggle
            centered>
            <template #heading>
                Website Inventory Review
            </template>
            <div
                :class="[
                    'mt-5 mx-5',
                    (!websiteHasSupport && currentWebsite) ? 'mb-4' : 'mb-5'
                ]">
                <v-alert
                    :value="error"
                    class="mb-4"
                    type="error">
                    {{ error }}
                </v-alert>

                <p class="mb-5">
                    This tool will allow you to see if BuyerBridge currently
                    supports retrieving inventory from your website.  This can
                    be useful prior to onboarding to guage turnaround times
                    as using website inventory can allow for immediate onboarding
                    whereas feed inventory typically requires longer turnaround
                    times.
                </p>

                <v-form
                    ref="form"
                    v-model="formValid"
                    class="layout"
                    @submit.prevent="() => false">
                    <v-text-field
                        v-model="website"
                        label="Enter Website"
                        :rules="websiteFieldRules"
                        class="styled-field styled-field-large mr-3" />
                    <styled-button
                        :disabled="!formValid"
                        :loading="inventoryCheckLoading"
                        @click="checkWebsiteInventorySupport">
                        Check Inventory
                    </styled-button>
                </v-form>
            </div>

            <div
                v-if="inventoryCheckLoading"
                class="pt-5">
                <!-- Placeholder for padding -->
            </div>

            <template v-else>
                <div
                    v-if="websiteHasSupport"
                    class="pa-5">
                    <website-inventory :website="currentWebsite" />
                </div>

                <styled-callout
                    v-else-if="!websiteHasSupport && currentWebsite"
                    class="layout align-center mt-4 mx-5 mb-5">
                    <div class="flex shrink mr-3">
                        <status-icon
                            value="error"
                            size="25" />
                    </div>

                    <div class="flex">
                        The website you entered ({{ currentWebsite }}) is not a supported website!
                        Please check the URL to ensure it's correct or try another.  There are many sites
                        that we cannot support but many that we do so don't give up!
                    </div>
                </styled-callout>
            </template>
        </styled-card>
    </div>
</template>

<script>
import WebsiteInventory from '../globals/WebsiteInventory';
import StyledCard from '../globals/StyledCard';
import StatusIcon from '../globals/StatusIcon';
import StyledCallout from '../globals/StyledCallout';
import StyledButton from '../globals/StyledButton';
import { requiredRules, domainRules } from '../../helpers/validationRules';
import getRootDomain from '../../helpers/getRootDomain';

export default {
    title: 'Website Inventory Review',
    name: 'WebsiteInventoryReview',
    components: {
        WebsiteInventory,
        StyledCard,
        StyledButton,
        StyledCallout,
        StatusIcon
    },
    data() {
        return {
            websiteFieldRules: [ ...requiredRules, ...domainRules ],
            website: '',
            websiteHasSupport: null,
            currentWebsite: null,
            inventoryCheckLoading: false,
            formValid: false,
            error: null
        };
    },
    computed: {
        rootDomain() {
            if (!this.currentWebsite) {
                return null;
            }

            return getRootDomain(this.currentWebsite);
        }
    },
    watch: {
        currentWebsite(website) {
            if (this.$route.query.website !== website) {
                this.$router.push({
                    query: {
                        website: this.website
                    }
                });
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        isValidUrl(string) {
            try {
                const url = new URL(string);
                return url;
            } catch (error) {
                return false;
            }
        },
        trimUrl() {
            const validUrl = this.isValidUrl(this.website);
            if (validUrl) {
                this.website = validUrl.hostname.replace('www.', '');
            }
        },
        async init() {
            const routeWebsite = this.$route.query.website;
            if (!routeWebsite) {
                return;
            }

            this.website = routeWebsite;

            await this.$nextTick();

            this.$refs.form.validate();

            await this.$nextTick();

            if (this.formValid) {
                this.checkWebsiteInventorySupport();
            }
        },
        async checkWebsiteInventorySupport() {
            this.trimUrl();
            try {
                this.currentWebsite = this.website;
                this.inventoryCheckLoading = true;
                this.error = null;
                this.websiteHasSupport = false;

                const response = await this.$apiRepository.checkWebsiteInventorySupport(this.rootDomain);
                this.websiteHasSupport = Boolean(response);
            } catch (error) {
                console.error('Error checking website inventory', error);
                this.error = 'Error checking website inventory';
            } finally {
                this.inventoryCheckLoading = false;
            }
        }
    }
};
</script>
